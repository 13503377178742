import React from 'react'

import HeroSingle from '../components/HeroSingle'
import CTABlock from '../components/CTABlock'
import PlansTable from '../components/PlansTable'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'

class Compare_plans extends React.Component {
  render() {
    const { data, location } = this.props
    return (
      <Layout location={location}>
        <SEO path={location.pathname} frontmatter={data.page.frontmatter} />
        <div className="position-relative">
          <HeroSingle data={data.page} />
          <CTABlock compact data={data.page.frontmatter.cta_block} />
        </div>

        <PlansTable />
      </Layout>
    )
  }
}

export default Compare_plans

export const plansQuery = graphql`
  query PlansQuery {
    page: markdownRemark(fields: { slug: { eq: "/compare_plans/" } }) {
      ...SEO
      ...ctaBlock
      ...ctaBanner
      ...HeroSingle
      id
    }
  }
`
